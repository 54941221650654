.About {
    background-image: url("../../assets/images/djingbackgroundimage.png");
    height: 100vh; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    font-family: Montserrat;
    color: rgba(255, 255, 255, 0.959);
    .aboutContainer{
        height: inherit;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-direction: column;
            .imgContainer{
                margin: auto 1rem 0;
                border: none;
                background: transparent;
            }
            .aboutMeContainer{
                width: 300px;
                margin: auto;
                margin-top: -5rem;
            }
    
    }
}

 /* X-Small devices (portrait phones, less than 576px) */
 /* No media query for `xs` since this is the default in Bootstrap */

 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 499.98px) { 
   .About .aboutContainer{
            .aboutMeContainer{
                width: 30rem;
            }
        }
}

 /* Medium devices (tablets, 768px and up) */
@media (min-width: 634px) { 
    .About .aboutContainer{
        .imgContainer{
            width: 18rem;
        }
        .aboutMeContainer{
            width: 36rem;
            margin-top: -5rem;
        }

    }
}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 900px) { 
    .About .aboutContainer{
        flex-direction: row;
            .imgContainer{
                width: 67%;
                margin: auto 5rem;
            }
            .aboutMeContainer{
                width: 32rem;
                margin: 0;
                margin-left: -20rem;
            }
    
        }
}




