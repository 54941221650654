.Venue {
    background-image: url("../../assets/images/Venue-backgroundImage.png");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: rgba(255, 255, 255, 0.959);
    .venueContainer{
        height: inherit;
        justify-content: center;
        align-items: center;
        padding: 3rem;
        .venuePlayed{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32em;
        padding: 0.5rem;
        background: #d2cdcf;
        }
        .venueListContainer{
            .venueCity{
                padding: 8px 16px;
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                background: #201623;
                color: white;
            }
            .venueItem{
                font-size: 14px;
                font-weight: 600;
                line-height: 17px;
            }
        }
    }
}