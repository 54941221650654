.modal-backdrop.show {
    opacity: 0
  }

  .App{
    font-family: Montserrat;
    font-style: normal;
    position: relative;
  }
  .toastContainer{
    position: absolute;
    bottom: 136px;
    right: 0;
  }