.Booking {
    background-image: url("../../assets/images/theyLike.jpeg"); 
    min-height: 100vh; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: #afaaaa;
    padding-top: 15rem
} 

/* // small devices (tablets, phones, 767.98px and down) */
@media (max-width: 991.98px) { 
    .Booking {
        padding-top: 8rem
    } 
}