.Nav {
    position: absolute;
    font-family: Montserrat Alternates;
    z-index: 2;
    .logo {
    height: 87.55px;
  }
}

.navLink {
  background: #040206 !important;
  color: white !important;
  text-decoration: none;
  &:hover{
    color: #F590C3!important
  }
}
.isActive{
  color: #F590C3 !important;
  border-bottom: 2px solid;
}

/* // small devices (tablets, phones, 767.98px and down) */
@media (min-width: 991.98px) { 
  .navLink {
    background: transparent !important;
    
  }
}
