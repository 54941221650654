.HomeSection {
    background-image: url("../../assets/images/main2.png");
    height: 100vh; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    font-family: Montserrat;
    color: rgba(255, 255, 255, 0.959);
    position: relative;
    .overLayContainer{
        position: absolute;
        bottom: 1rem;
        .title{
            font-size: 3rem;
            font-family: 'Allura', cursive;
            font-weight: 400;
        }
        .description {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            word-wrap: break-word;
            max-width: 36rem;
          }
    }
}


@media (max-width: 767.98px) { 
    .HomeSection{
      background-image: url("../../assets/images/main.png"); 
    }
  }

  @media (min-width: 992px) { 
    .HomeSection .overLayContainer{
      bottom: 10rem;
    }
  }