
.Footer{
  background: #010708;
  .footerContainer{
    justify-content: center;
    align-items: center;
    .logo{
      width: 100%;
    }
    .socialMediaContainer{
      align-items: center;
      color: white;
      .socialMediaTitle{
        margin-right: 1rem;
      }
    }
  }
  
} 
  

  
  