.Music {
    background: #010708;
    color: white;
    padding: 2rem;
    .musicContainer{
        height: inherit;
        padding: 7rem 0;
        .musicCardContainer{
            background: none;
            width: 85%;
            margin: auto;
        }
        .musicSoundContainer{
           color: white;
           .listenToDJ{
            min-width: 100px;
            max-width: 109px;
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;

           }
            .SoundContainer{
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }
}