.Home {
  background-image: url("../../assets/images/main2.png"); 
  /* height: 100vh;  */
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  color: #afaaaa;
}

/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .Home{
    background-image: url("../../assets/images/main.png"); 
  }
}
