.BookingForm {
    padding-left: 8rem;
    padding-bottom: 3rem;
    .BookingFormContainer{
        width: 40rem;
        border: 2px dotted white;
        background: #ebeaff;
        .FormDropdown{
            border: 1px solid #CBCBCB;
            color: black;
            background: none;
            width: 14rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .submitBtn{
            color: black;
            background: linear-gradient(180deg, #FF8AC5 15.58%, rgba(246, 205, 226, 0.8) 152.6%);
        }
        .formInput{
            background: #ebeaff;
            border: 1.2px solid #CBCBCB;
            &:focus{
                border: 1px solid #1976D2;
                box-shadow: none
            }
        }
    }
}



/* // small devices (tablets, phones, 767.98px and down) */
@media (max-width: 767.98px) { 
   .BookingForm {
      padding: 1rem;
   .BookingFormContainer {
        margin: auto;
        width: initial;
    }
}
 }