.Services {
    background-image: url("../../assets/images/services.png");
    height: 100vh; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    color: #afaaaa;
    .cardContainer{
        height: inherit;
        justify-content: center;
        align-items: center;
        .tabCard{
            width: 18rem;
            height: 20rem;
            background: #2e2e2e8f;
            color: white;
        }
    }
}


/* `lg` applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .Services{
        height: initial;
        background-image: url("../../assets/images/servicesMobile.png");
        .cardContainer{
            flex-direction: column;
            .tabCard{
                width: 22rem;
                height: 17rem;
                
            }
        }

    }
}